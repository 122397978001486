// Media Query
let mqXS = window.matchMedia("(max-width: 576px)");
let mqSM = window.matchMedia( "(max-width: 768px)" );

// Site Navigation
let toggle = document.getElementById('toggle');
let menu = document.getElementById('site-navigation');
let navWrapper = document.querySelector('.navigation-wrapper');

toggle?.addEventListener('click', function () {
    document.getElementsByTagName( 'html' )[0].classList.toggle('lock-scroll');
    navWrapper?.classList.toggle('open'); 
    toggle?.classList.toggle('toggle-active'); 
  	if (menu?.classList.contains('is-active')) {
    	menu?.classList.remove('is-active');
  	} else {
    	menu?.classList.add('is-active'); 
  	}
});

// Modal
document.addEventListener('click', function (e) {
    let target = e.target;

    if (target?.getAttribute('data-toggle') == 'modal' && target?.hasAttribute('data-target')) {
        document.getElementById(target.getAttribute('data-target'))?.classList.add('open');
        document.querySelector('body').classList.add('prevent-scroll');
        e.preventDefault();
    }

    // Close modal window with 'data-dismiss' attribute or when the backdrop is clicked
    if (target?.getAttribute('data-dismiss') == 'modal' || target?.classList.contains('modal')) {
        document.querySelector('[class="modal open"]')?.classList.remove('open');
        document.querySelector('body').classList.remove('prevent-scroll');
        e.preventDefault();
    }
}, false);

const accordionBtns = document.querySelectorAll('.modal .accordion-toggle');
accordionBtns.forEach((accordion) => {
    accordion.addEventListener('click', function (e) {
        e.target?.classList.toggle("accordion-open");
        let content = e.target?.nextElementSibling;
    });
});

// Amimation on page scroll
if (window.gsap && window.ScrollTrigger) {
    gsap.registerPlugin(ScrollTrigger);

    // Zoom in full width graphic
    let graphics = document.querySelectorAll('.graphic');
    Array.prototype.slice.call(graphics).forEach(function (graphic) {
        ScrollTrigger.create({
            trigger: graphic,
            start: "top center",
            onEnter: () => zoomGraphic(),
            onLeaveBack: () => zoomGraphic(),
            toggleActions: "restart pause reverse pause",
        });
        function zoomGraphic() {
            graphic.classList.toggle('zoom-in')
        };
    });

    // Pull up blurb container
    gsap.to(".blurb.video-blurb .container", {
        yPercent: -12,
        scrollTrigger: {
            trigger: ".video-blurb",
            start: "top 95%",
            end: "bottom center",
            toggleActions: "restart pause reverse pause",
            scrub: 1,
        },
    });

    /* PULL UP IMAGE BLURB */
    document.querySelectorAll(".blurb.image-blurb .container").forEach(function (container) {
        gsap.set(container, {
            y: 88
        });
    });
    gsap.to(".blurb.image-blurb .container", {
        y: -88,
        scrollTrigger: {
            trigger: ".image-blurb",
            start: "top 70%",
            end: "bottom 90%",
            toggleActions: "restart pause reverse pause",
            scrub: 1,
        },
    });

    // Zoom in column image
    const columnsImages = gsap.utils.toArray('.column-image');

    columnsImages?.forEach(columnImage => {
        gsap.to(".column-image", {
            duration: 5,
            scrollTrigger: {
                trigger: columnImage,
                start: "top 90%",
                onEnter: () => zoomColImage(),
                onLeaveBack: () => zoomColImage(),
                scrub: true
            },
        });
        function zoomColImage() {
            columnImage.classList.toggle('zoom-in')
        };
    });

    // Pull up column copy
    const columnsCopyBoxes = gsap.utils.toArray('.column-frame');
        columnsCopyBoxes?.forEach(columnsCopyBox => {
        gsap.to(columnsCopyBox, {
            yPercent: -10,
            scrollTrigger: {
                trigger: columnsCopyBox,
                toggleActions: "restart pause reverse pause",
                scrub: 1,
            },
        });
    });

    /* SHIFT MASONRY ITEMS */
    if (!mqSM.matches) {
        document.querySelectorAll('.masonry-wrapper').forEach(function (masonryWrapper, index) {
            let reversed = index % 2 === 0 ? 1 : -1;
       
            masonryWrapper.querySelectorAll('.masonry-item').forEach(function (masonryItem, index) {
                gsap.set(masonryItem, {
                    y: index * reversed * -70,
                });
                let direction = index % 2 ? 1 : -1;
                gsap.to(masonryItem, {
                    y: direction * reversed * 70,
                    scrollTrigger: {
                        trigger: masonryWrapper,
                        start: "top 90%",
                        toggleActions: "restart pause reverse pause",
                        scrub: 1
                    },
                });
            });
        });
    }
}
